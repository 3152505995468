// import { Component, OnInit } from '@angular/core';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
// import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiService } from '../services/api.service';
import { NotificationService } from 'src/app/services/notification.service';
// import { NgForm } from '@angular/forms';
// import { ChatsService } from 'src/app/services/chats.service';
// import { MessageService } from 'primeng/api';
import { AngularFireAuth } from '@angular/fire/auth';
// declare var jQuery: any;

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit, AfterViewInit {
  username = '';
  avatarUrl = '';
  // @Input() isShowToast = false
  model = {
    oldPassword: '',
    newPassword: '',
    userLogin: ''
  };

  confirmVal: '';
  status = true;
  statusServ = true;
  // @ViewChild('change', { static: false }) changeForm: NgForm;
  constructor(
    // private chatService: ChatsService,
    // private route: ActivatedRoute,
    // private router: Router,
    private authService: AuthService,
    private afAuth: AngularFireAuth,
    private apiService: ApiService,
    public notificationService: NotificationService
    // private modalService: NgbModal,
    // private messageService: MessageService
  ) { }
  iduser;
  // loadForm
  ngAfterViewInit() {
    // this.loadForm = false;
    this.afAuth.authState.subscribe((auth) => {
      localStorage.setItem('userID', auth ? auth.uid : '');
      this.iduser = auth ? auth.uid : '';
      // this.getData();
    });

  }
  // signout() {
  //     this.authService.signout();
  // }

  ngOnInit() {
    this.username = this.authService.getUserName();
    this.avatarUrl = this.authService.getUserImage();

  }

  handleSubmit(event) {
    event.preventDefault();
    this.model.userLogin = this.username;
    if (this.model.newPassword !== this.confirmVal) {
      this.status = false;
      return;
    }

    this.apiService.changePassword(this.model)
      .subscribe((result: any) => {
        if (result === 'success') {
          this.notificationService.showNotification('Đổi mật khẩu thành công', 1);

        } else {
          this.statusServ = false;

        }
      });
  }
}
