import { Observable } from 'rxjs/Observable';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
const apiBaseUrl = 'https://apiscrm.sunshinegroup.vn';
@Injectable()
export class BaseApiService {
    constructor(private http: HttpClient,
                private authService: AuthService) { }

    options = {
        headers: new HttpHeaders({
            'Authorization': this.authService.getAuthorizationHeaderValue(),
            'Content-Type': 'application/json',
        })
    };

    /**
     * Get Request
     * This method used to make calls get a request to the server to get one or many items in the database
     * @param endpointUrl endpoint of url
     * @return Observable<any> data of request
     */
    getRequest(endpointUrl): Observable<any> {
        return this.http.get<any>(apiBaseUrl + `/api/v1/${endpointUrl}`, this.options);
    }

    /**
     * This method used to make calls a put request to the server to update the database
     * @param endpointUrl endpoint of url
     * @param data: data using to update
     * @return Observable<any> data of request
     */
    putRequest(endpointUrl, data): Observable<any> {
        return this.http
            .put<any>(`${apiBaseUrl}/api/v1/${endpointUrl}`, data, this.options);
    }

    /**
     * This method used to make calls a post request to the server to create a new item in the database
     * @param endpointUrl endpoint of url
     * @param data: data using to update
     * @return Observable<any> data of request
     */
    postRequest(endpointUrl, data): Observable<any> {
        return this.http
            .post<any>(`${apiBaseUrl}/api/v1/${endpointUrl}`, data, this.options);
    }

    /**
     * This method used to make calls a delete request to the server to delete a item in the database
     * @param endpointUrl endpoint of url
     * @param data: data using to update
     * @return Observable<any> data of request
     */
    deleteRequest(endpointUrl): Observable<any> {
        return this.http
            .delete<any>(`${apiBaseUrl}/api/v1/${endpointUrl}`);
    }
}
