import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
const apiBaseUrl = environment.apiBase;


@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    private authService: AuthService) { }

  options = {
    headers: new HttpHeaders({
      'Authorization': this.authService.getAuthorizationHeaderValue(),
      'Content-Type': 'application/json',
    })
  }

  getWebManagements(): Observable<any> {
    return this.http.get(`${apiBaseUrl}/Identity/GetWebManagements`, this.options);
  }


  changePassword(params): Observable<string> {
    return this.http
    .put<string>(`${apiBaseUrl}/api/v1/user/ChangePassword`, {...params}, {...this.options, responseType: 'text' as 'json'});
  }
}
