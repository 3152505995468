import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasePagingComponent } from './base-paging.component';
import { BasePagingtwoComponent } from '../base-paging-two/base-paging-two.component';
import { BasePagingthreeComponent } from '../base-paging-three/base-paging-three.component';
import { RouterModule } from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    BasePagingComponent,
    BasePagingtwoComponent,
    BasePagingthreeComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

  ],
  exports: [
    BasePagingComponent,
    BasePagingtwoComponent,
    BasePagingthreeComponent
  ]
})
export class BasePagingModule { }
