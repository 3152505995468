import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  // public menuItems: any[];
  // private manager: UserManager = new UserManager(environment.authenSettings);
  constructor(
      private router: Router,
      private authService: AuthService,
      private route: ActivatedRoute)
      { }

  username = '';
  avatarUrl = '';

  ngOnInit() {
      this.username = this.authService.getUserName();
      this.avatarUrl = this.authService.getUserImage();
  }

}
