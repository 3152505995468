// import { Component, OnInit } from '@angular/core';
// import { Router, ActivatedRoute } from '@angular/router';
// import { AuthService } from '../../services/auth.service';
// import { ProjectInfoComponent } from './../../project-info/project-info.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiService } from '../../services/api.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NgForm } from '@angular/forms';
// import { ChatsService } from 'src/app/services/chats.service';
import { MessageService } from 'primeng/api';
import { AngularFireAuth } from '@angular/fire/auth';
declare var jQuery: any;
@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [MessageService]
})

export class NavbarComponent implements OnInit, AfterViewInit {
    username = '';
    avatarUrl = '';
    @Input() isShowToast = false
    model = {
        oldPassword: '',
        newPassword: '',
        userLogin: ''
    };

    confirmVal: '';
    status = true;
    statusServ = true;
    @ViewChild('change', { static: false }) changeForm: NgForm;
    constructor(
        // private chatService: ChatsService,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private afAuth: AngularFireAuth,
        private apiService: ApiService,
        public notificationService: NotificationService,
        private modalService: NgbModal,
        private messageService: MessageService
    ) { }
    iduser;
    loadForm
    ngAfterViewInit() {
        this.loadForm = false;
        this.afAuth.authState.subscribe((auth) => {
            localStorage.setItem('userID', auth ? auth.uid : '');
            this.iduser = auth ? auth.uid : '';
            // this.getData();
        });

    }
    signout() {
        this.authService.signout();
    }

    ngOnInit() {
        this.username = this.authService.getUserName();
        this.avatarUrl = this.authService.getUserImage();

    }

    handleSubmit(event) {
        event.preventDefault();
        this.model.userLogin = this.username;
        if (this.model.newPassword !== this.confirmVal) {
            this.status = false;
            return;
        }

        this.apiService.changePassword(this.model)
            .subscribe((result: any) => {
                if (result === 'success') {
                    this.notificationService.showNotification('Đổi mật khẩu thành công', 1);
                    jQuery('#changePassword').modal('hide');
                    this.changeForm.resetForm();
                } else {
                    this.statusServ = false;
                }
            });
    }

    // handleSetProjectInfo() {
    //   const modal = this.modalService.open(ProjectInfoComponent, { size: 'xl', windowClass: 'modal-holder' });
    // }
    // itemUnreads = [];
    // getData() {
    //     console.log(this.iduser);
    //     this.chatService.setChildCollection(this.iduser, 'threads');
    //     this.chatService.getChatRoom().onSnapshot((snap) => {
    //         const items = snap.docs.map((item) => item.data());
    //         // this.itemitem.unread === 1
    //         this.itemUnreads = items.filter(d => d.unread === 1);
    //         if (!this.isShowToast && this.loadForm && this.itemUnreads.length > 0) {
    //             this.messageService.add({ severity: 'info', summary: 'Thông báo', detail: `${this.itemUnreads[0].name}: ${this.itemUnreads[0].last_message.payload}` });
    //             this.playAudio();
    //         }
    //         this.loadForm = true;
    //     });
    // }

    // playAudio() {
    //     let audio = new Audio();
    //     audio.src = "https://firebasestorage.googleapis.com/v0/b/sunshine-super-app.appspot.com/o/s-housing%2Fnotifi.mp3?alt=media&token=f53ad8a3-c1d6-4baa-a7a4-2482b9000dd8";
    //     audio.load();
    //     audio.play();
    // }
}
