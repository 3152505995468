import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import WhereFilterOp = firebase.firestore.WhereFilterOp;
import OrderByDirection = firebase.firestore.OrderByDirection;
import CollectionReference = firebase.firestore.CollectionReference;
import Query = firebase.firestore.Query;

@Injectable()
export class FireBaseService {
    constructor(
    ) {
    }
    /**
     * Method used to used get all document in collection
     * @param collectionName: Name of collection want to get data
     * @return Promise<SnapshotChange>: List of document in collection
     */
    getAllDocument(collectionName) {
        return firebase.firestore().collection(collectionName).get();
    }

    getAllDocumentChildCollectionName(collectionName, documentName, childCollectionName) {
        return firebase.firestore().collection(collectionName).doc(documentName).collection(childCollectionName).get();
    }

    getDocumentByRef(path) {
        return firebase.firestore().doc(path).get();
    }

    getCollectionByPath(path) {
        return firebase.firestore().collection(path).get();
    }

    getDocumentInCollectionByCondition(collectionName, fieldName, searchString, fieldConditionName, fieldValue) {
        return firebase.firestore().collection(collectionName)
        .where(fieldName, '==', searchString)
        .where(fieldConditionName, '==', fieldValue)
        .get();
    }

    deleteDocumentByDocumentName(collectionName, documentName) {
        return firebase.firestore().collection(collectionName).doc(documentName).delete();
    }

    getAllDocChildByPath(path) {
      return firebase.firestore().collection(path).get();
    }

    deleteDocByPathAndId(path, docId) {
      return firebase.firestore().collection(path).doc(docId).delete();
    }
}
