import { Component, OnInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { RouteInfo } from "./sidebar.metadata";
import { Router, ActivatedRoute } from "@angular/router";
import { UserManager } from 'oidc-client';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';

declare var $: any;
@Component({
    // moduleId: module.id,
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    // public menuItems: any[];
    // private manager: UserManager = new UserManager(environment.authenSettings);
    constructor(
        private router: Router,
        private authService: AuthService,
        private afAuth: AngularFireAuth,
        private apiService: ApiService,
        private route: ActivatedRoute)
        { }

    username = '';
    avatarUrl = '';

    ngOnInit() {
        $.getScript('./assets/app/js/core/app-menu.js');
        $.getScript('./assets/app/js/core/app.js');
        // if (localStorage.getItem('menuItems')) {
        //     this.menuItems = JSON.parse(localStorage.getItem('menuItems'));
        //     this.menuItems = this.menuItems.filter(menuItem => menuItem);
        //   } else {
        //     this.manager.getUser().then(user => {
        //         this.apiService.getListMenuByUserId(user.profile.sub, '16653310-AACC-452F-80D1-9B97BC8B017F').subscribe(results => {
        //             this.menuItems = results.data.filter(menuItem => menuItem);
        //             localStorage.setItem('menuItems',JSON.stringify(results.data));
        //         })
        //       });
        //   }
        this.username = this.authService.getUserName();
        console.log(this.avatarUrl);
        this.avatarUrl = this.authService.getUserImage()  || '/assets/images/avatar.jpg' ;
    }

    signout() {
        this.authService.signout();
    }


}
