import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import WhereFilterOp = firebase.firestore.WhereFilterOp;
import OrderByDirection = firebase.firestore.OrderByDirection;

@Injectable()
export class ApiStorageService {
    constructor() {
        
    }
    public storage = firebase.storage();
    public storageRef = this.storage.ref();
    async deletefileStorage(path) {
       return await this.storageRef.child(path).delete();
    }

    deletefileThumbsStorage(path) {
       const arrSize = ['256x256', '512x512', '1024x1024'];
       let sliptPath = path.split('.');
       const promises = [];
       for (const item of arrSize) {
        promises.push(this.deletefileStorage(`${sliptPath[0]}_${item}.${sliptPath[1]}`).catch(err => { }));
      }
         return Promise.all(promises)
    }

    
}