import { environment } from 'src/environments/environment';
// services
import { ApiService } from './services/api.service';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { ExportFileService } from './services/export-file.service';
import { FireBaseService } from './services/firebase.service';
import { ChatsService } from './services/chats.service';
import { ApiStorageService } from './services/api-storage.service';
import { AuthService } from './services/auth.service';
import { AuthGuardService } from './services/auth-guard.service';
import { BaseApiService } from './services/base-api.service';
import { NotificationService } from './services/notification.service';
import { FirebaseAuthService } from './services/firebase-auth.service';
import { TransfereService } from './services/transfere.service';

// components
import { AppComponent } from './app.component';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { SunshineComponent } from './sunshine/sunshine.component';
// import { CustomerListComponent } from './customer/customer-list/customer-list.component';
// import { MembercardListComponent } from './membercard/membercard-list/membercard-list.component';
// import { MembercardEditComponent } from './membercard/membercard-edit/membercard-edit.component';
// import { SettingEmailComponent } from './setting/setting-email/setting-email.component';
// import { SettingMessageComponent } from './setting/setting-message/setting-message.component';
// import { SettingPolicyComponent } from './setting/setting-policy/setting-policy.component';
// import { SettingEmailEditComponent } from './setting/setting-email-edit/setting-email-edit.component';
// import { MembercardAddComponent } from './membercard/membercard-add/membercard-add.component';
// import { MembercardViewComponent } from './membercard/membercard-view/membercard-view.component';
// import { CustomerSendmailComponent } from './customer/customer-sendmail/customer-sendmail.component';
// import { CustomerSendsmsComponent } from './customer/customer-sendsms/customer-sendsms.component';
// import { GroupListComponent } from './group-list/group-list.component';
// import { SendhistoryComponent } from './sendhistory/sendhistory.component';
// import { SendemailhistoryComponent } from './sendemailhistory/sendemailhistory.component';
// import { GroupAddComponent } from './group-add/group-add.component';
// import { IssuelistComponent } from './issuelist/issuelist.component';
// import { PotentialistComponent } from './customer/potentialist/potentialist.component';
// import { PotentiainfoComponent } from './customer/potentiainfo/potentiainfo.component';
// import { HandoverHouseComponent } from './handover-house/handover-house.component';
// import { DetailHandoverComponent } from './handover-house/detail-handover/detail-handover.component';
// import { DeliveryHouseComponent } from './handover-house/delivery-house/delivery-house.component';
// import { ListTaskDetailComponent } from './handover-house/list-task-detail/list-task-detail.component';
// // import { DetailpotentiaComponent } from './customer/detailpotentia/detailpotentia.component';
// import { DetailcustomerComponent } from './customer/detailcustomer/detailcustomer.component';
// import { LoyalpointpageComponent } from './loyalpointpage/loyalpointpage.component';
// import { MessagePageComponent } from './customer/detailcustomer/message-page/message-page.component';
// import { EmailPageComponent } from './customer/detailcustomer/email-page/email-page.component';
// import { CardPageComponent } from './customer/detailcustomer/card-page/card-page.component';
// import { TransactionPageComponent } from './customer/detailcustomer/transaction-page/transaction-page.component';
// import { SearchcustomerComponent } from './customer/searchcustomer/searchcustomer.component';
// import { SearchmanagerbyuserroleComponent } from './customer/searchmanagerbyuserrole/searchmanagerbyuserrole.component';
// import { LoyalinfoComponent } from './customer/loyalinfo/loyalinfo.component';
// import { IssueInfoComponent } from './issuelist/issue-info/issue-info.component';
// import { IssuedetailComponent } from './issuelist/issuedetail/issuedetail.component';
import { ButtonRendererComponent } from './viewcommon/renderer/button-renderer.component';
import { CustomDateComponent } from './viewcommon/renderer/custom-date.component';
import { ButtonRendererComponent1 } from './viewcommon/renderer/button-renderer.component-1';
import { AvatarComponent } from './viewcommon/avatar/avatar.component';
// import { ChatComponent } from './chat/chat.component';
import { DetailCheckListComponent } from './ng-modal/check-list/check-listcomponent';

// modules
import { NgxLoadingModule } from 'ngx-loading';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-modialog';
import { BootstrapModalModule } from 'ngx-modialog/plugins/bootstrap';
import { MarkdownModule } from 'ngx-markdown';
import { LMarkdownEditorModule } from 'ngx-markdown-editor';
import { ScrollEventModule } from 'ngx-scroll-event';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { NgUploaderModule } from 'ngx-uploader';
import { CommonPagingModule } from './shared/components/common-paging/common-paging.module';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CKEditorModule } from 'ng2-ckeditor';
import { TreeviewModule } from 'ngx-treeview';
import { ChartsModule } from 'ng2-charts';
import {TagInputModule} from 'ngx-chips';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
// import { NgDatepickerModule } from 'ng2-datepicker';
import { HttpClientModule } from '@angular/common/http';
import { TabViewModule } from 'primeng/tabview';
import { ButtonModule } from 'primeng/button';
import { ToolbarModule } from 'primeng/toolbar';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import {SplitButtonModule} from 'primeng/splitbutton';
import {SelectButtonModule} from 'primeng/selectbutton';
import {DialogModule} from 'primeng/dialog';
import {} from 'primeng/tabview';
import {FieldsetModule} from 'primeng/fieldset';
import {CardModule} from 'primeng/card';
import {MultiSelectModule} from 'primeng/multiselect';
import {CheckboxModule} from 'primeng/checkbox';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {LightboxModule} from 'primeng/lightbox';
import {GalleriaModule} from 'primeng/galleria';
import {SidebarModule} from 'primeng/sidebar';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {MenubarModule} from 'primeng/menubar';
import { ChartModule } from 'primeng/chart';
import { BasePagingModule } from './viewcommon/base-paging/base-paging.module';
// import { ChatModule } from './chat/chat.module';
// import { SearchModule } from './customer/search/search.module';
// import { IssueInfoModule } from './issuelist/issue-info/issue-info.module';
import { ProfileComponent } from './profile/profile.component';
import { PasswordComponent } from './password/password.component';
@NgModule({
    declarations: [
        AppComponent,
        AuthCallbackComponent,
        // ChatComponent,
        SunshineComponent,
        // MembercardListComponent,
        // MembercardEditComponent,
        // SettingEmailComponent ,
        // SettingMessageComponent,
        // SettingPolicyComponent,
        // SettingEmailEditComponent,
        // MembercardAddComponent,
        // MembercardViewComponent,
        // CustomerSendmailComponent,
        // CustomerSendsmsComponent,
        // CustomerListComponent,
        // DetailcustomerComponent ,
        // MessagePageComponent,
        // EmailPageComponent 	,
        // CardPageComponent ,
        // TransactionPageComponent,
        // LoyalinfoComponent,
        // GroupListComponent,
        // SendhistoryComponent,
        // SendemailhistoryComponent,
        // GroupAddComponent,
        // PotentialistComponent,
        // PotentiainfoComponent,
        // DetailpotentiaComponent,
        // HandoverHouseComponent,
        // DetailHandoverComponent,
        // DeliveryHouseComponent ,
        // ListTaskDetailComponent,
        // LoyalpointpageComponent,
        // SearchmanagerbyuserroleComponent,
        // SearchcustomerComponent,

        // IssuelistComponent,
        // IssueInfoComponent ,
        // IssuedetailComponent,
        // DetailCheckListComponent,
        ButtonRendererComponent1,
        ButtonRendererComponent,
        AvatarComponent,
        ProfileComponent,
        PasswordComponent,
        // CustomDateComponent
    ],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        NgbModule,
        BasePagingModule,
        // NgDatepickerModule,
        FormsModule,
        // NgUploaderModule,
        ModalModule.forRoot(),
        BootstrapModalModule,
        // MarkdownModule.forRoot(),
        // LMarkdownEditorModule,
        // AngularMultiSelectModule,
        // ProgressbarModule.forRoot(),
        // ScrollEventModule,
        // BsDatepickerModule.forRoot(),
        // TreeviewModule.forRoot(),
        // CKEditorModule,
        // ChartsModule,
        // TagInputModule,
        HttpClientModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireStorageModule,
        AngularFireAuthModule,
        AngularFirestoreModule,
        // AngularFireDatabaseModule,
        // NgxLoadingModule,
        // ButtonModule,
        // CommonPagingModule,
        // AgGridModule.withComponents([
        //     ButtonRendererComponent,
        //     CustomDateComponent,
        //     ButtonRendererComponent1,
        //     AvatarComponent
        //   ]),
        // SplitButtonModule,
        // SelectButtonModule,
        // ToolbarModule,
        // DialogModule,
        // TabViewModule,
        // FieldsetModule,
        // CardModule,
        // MultiSelectModule,
        // CheckboxModule,
        // DropdownModule,
        // InputTextModule,
        // ScrollPanelModule,
        // ToolbarModule,
        // LightboxModule,
        // GalleriaModule,
        // SidebarModule,
        // BreadcrumbModule,
        // MenubarModule,
        // ChartModule,
        // ChatModule,
        // SearchModule,
        // IssueInfoModule
    ],
    providers: [
        AuthService,
        AuthGuardService,
        ExportFileService,
        HttpInterceptorService,
        ApiService,
        FirebaseAuthService,
        NotificationService,
        // TransfereService,
        BaseApiService,
        FireBaseService,
        // ChatsService,
        ApiStorageService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
