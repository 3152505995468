import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';

/**sunshine component */
import { SunshineComponent } from './sunshine/sunshine.component';
import { HomeComponent } from './home/home.component';
// import { CustomerListComponent } from './customer/customer-list/customer-list.component';
// import { MembercardListComponent } from './membercard/membercard-list/membercard-list.component';
// import { MembercardEditComponent } from './membercard/membercard-edit/membercard-edit.component';
// import { SettingEmailComponent } from './setting/setting-email/setting-email.component';
// import { SettingMessageComponent } from './setting/setting-message/setting-message.component';
// import { SettingPolicyComponent } from './setting/setting-policy/setting-policy.component';
// import { SettingEmailEditComponent } from './setting/setting-email-edit/setting-email-edit.component';
// import { CustomerSendmailComponent } from './customer/customer-sendmail/customer-sendmail.component';
// import { CustomerSendsmsComponent } from './customer/customer-sendsms/customer-sendsms.component';
// import { GroupListComponent } from './group-list/group-list.component';
// import { IssuelistComponent } from './issuelist/issuelist.component';
// import { MembercardViewComponent } from './membercard/membercard-view/membercard-view.component';
// import { MembercardAddComponent } from './membercard/membercard-add/membercard-add.component';
// import { SendhistoryComponent } from './sendhistory/sendhistory.component';
// import { SendemailhistoryComponent } from './sendemailhistory/sendemailhistory.component';
// import { ChatComponent } from './chat/chat.component';
// import { PotentialistComponent } from './customer/potentialist/potentialist.component';
// // import { DetailpotentiaComponent } from './customer/detailpotentia/detailpotentia.component';
// import { HandoverHouseComponent } from './handover-house/handover-house.component';
// import { ListTaskDetailComponent } from './handover-house/list-task-detail/list-task-detail.component';
// import { DeliveryHouseComponent } from './handover-house/delivery-house/delivery-house.component';
// import { DetailcustomerComponent } from './customer/detailcustomer/detailcustomer.component';
// import { LoyalpointpageComponent } from './loyalpointpage/loyalpointpage.component';
// import { IssuedetailComponent } from './issuelist/issuedetail/issuedetail.component';
import { ProfileComponent } from './profile/profile.component';
import { PasswordComponent } from './password/password.component';


const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  { path: 'auth-callback', component: AuthCallbackComponent },
  /**Sunshine route */
  {
    path: ''
     , component: SunshineComponent
    ,
    data: { title: '' }, children: [
         { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) }
         , { path: 'profile', component: ProfileComponent }
         , { path: 'password', component: PasswordComponent }
      //  , {path: 'potentialist', loadChildren: () => import('./customer/potentia/potentia.module').then(m => m.PotentiaModule)}
      //  , { path: 'customerlist', component: CustomerListComponent }
      //  , { path: 'customerdetail/:custId', component: DetailcustomerComponent }
      //  , {path: 'customerlist', loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule)}
      //  , { path: 'membercardedit/:cardNum', component: MembercardEditComponent }
      //  , { path: 'membercardview/:cardNum', component: MembercardViewComponent }
      //  , { path: 'membercardlist', component: MembercardListComponent }
      //  , { path: 'membercardadd', component: MembercardAddComponent }
      //  , { path: 'settingmessage', component: SettingMessageComponent }
      //  , { path: 'settingemail', component: SettingEmailComponent }
      //  , { path: 'settingpolicy', component: SettingPolicyComponent }
      //  , { path: 'settingemailedit/:id', component: SettingEmailEditComponent }
      //  , { path: 'sendmail', component: CustomerSendmailComponent }
      //  , { path: 'sendsms', component: CustomerSendsmsComponent }
      //  , { path: 'grouplist', component: GroupListComponent }
      //  , { path: 'sendhistory', component: SendhistoryComponent }
      //  , { path: 'sendemailhistory', component: SendemailhistoryComponent }
      //  , { path: 'chat', loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule) }
      //  , { path: 'issuelist', component: IssuelistComponent }
      //  , { path: 'detailissue/:issueId', component: IssuedetailComponent }
      // ,  { path: 'issuelist', loadChildren: () => import('./issuelist/issuelist.module').then(m => m.IssuelistModule) }
      //  , { path: 'potentialist', component: PotentialistComponent }
      //  , { path: 'detailpotentia/:id', component: DetailpotentiaComponent }
      //  , { path: 'loyalpointpage', component: LoyalpointpageComponent }
      //  , { path: 'loyaltransactionpage', loadChildren:
      //             () => import('./loyal-transaction/loyal-transaction.module').then(m => m.LoyalTransactionModule)}
      //  , { path: 'handover', component: HandoverHouseComponent }
      //  , { path: 'detail-handover-room/:handOverDetailId', component: DeliveryHouseComponent }
      //  , { path: 'list-task-detail/:exchangeId', component: ListTaskDetailComponent }
      // , {path: 'view', loadChildren: () => import('./view/view.module').then(m => m.ViewModule)}
    ], canActivate: [AuthGuardService]

  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
